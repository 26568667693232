.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  height: 100vh;
  width: 100%;
}

.left {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  box-sizing: border-box;
  border: 1px solid black;
}

.left pre {
  padding: 16px !important;
}

.left textarea {
  padding: 16px !important;
}

.right {
  grid-column-start: 2;
  grid-row-start: 2;
  box-sizing: border-box;
  margin: 0px !important;
}

h1 {
  font-family: sans-serif;
  padding-left: 16px;
}

.ocaml {
  grid-column-start: 2;
  grid-row-start: 1;
}

.python {
  grid-column-start: 1;
  grid-row-start: 1;
}
